import request from '@/utils/request'

export function fetchList(query) {
    return request({
        url: '/admin/article/list',
        method: 'get',
        params: query
    })
}

export function saveArticle(data) {
    return request({
        url: '/admin/article/save',
        method: 'post',
        data
    })
}

export function delArticle(data) {
    return request({
        url: '/admin/article/delete',
        method: 'post',
        data
    })
}
