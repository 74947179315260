<template>
	<div class="examine">
		<div class="wrapper">
			<div class="screen">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>公告管理</el-breadcrumb-item>
				</el-breadcrumb>
				<el-button type="primary" @click="noticeAdd" class="main-btn">新建公告</el-button>
			</div>
			<el-table :data="tableData" class="main-table" header-row-class-name="table-header"
				cell-class-name="table-hover" :row-class-name="tableRowClassName" @sort-change="handleSort"
				:default-sort="{prop: 'modifyTime', order: 'descending'}">
				<el-table-column label="序号" type="index" :index="indexMethod" width="80" align="center">
				</el-table-column>
				<el-table-column prop="title" label="标题"></el-table-column>
				<el-table-column prop="createTime" label="录入时间" sortable="custom" align="center"></el-table-column>
				<el-table-column label="操作" align="center" class-name="table-operate">
					<template slot-scope="scope">
						<el-button class="operation-btn" type="primary" size="small" title="编辑"
							@click="editClick(scope.row)">编辑
						</el-button>
						<el-button class="" type="danger" size="small" title="删除" @click="deleteClick(scope.row)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="total, prev, pager, next" :total="this.total"
				:current-page.sync="query.page" :page-size="query.pagesize" @current-change="getList">
			</el-pagination>
		</div>
		<!-- 新建公告 -->
		<el-dialog title="新建公告" :visible.sync="dialogFormVisible" width="80%" :append-to-body="true" :destroy-on-close="true"
			custom-class="main-dialog">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
				<el-form-item label="公告标题" prop="title">
					<el-input v-model="ruleForm.title" autocomplete="off" placeholder="请输入公告标题"></el-input>
				</el-form-item>
				<el-form-item label="公告内容" prop="content">
					<quill-editor ref="text" v-model="ruleForm.content" :options="editorOption"
						@change="onEditorChange($event)" />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">立即提交</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		quillEditor
	} from 'vue-quill-editor'
    import {delArticle, fetchList, saveArticle} from "../../api/article";

	export default {
		name: "examine",
		components: {
			quillEditor
		},
		data() {
			return {
				editorOption: {
					placeholder: "请输入内容"
				},
				ruleForm: {
                    id: '',
					title: '',
					content: ''
				},
				rules: {
					title: [{
						required: true,
						message: '请输入公告名称',
						trigger: 'blur'
					}],
					content: [{
						required: true,
						message: '请输入公告内容',
						trigger: 'blur'
					}],
				},
				dialogFormVisible: false,
				downloadLoading: false,
				tableData: [],
				query: {
					page: 1,
					pagesize: 10,
					sort: '-createTime',
				},
				total: ''
			};
		},
		created() {
			this.getList();
		},
		methods: {
			onEditorChange({
				quill,
				html,
				text
			}) {
				console.log('editor change!', quill, html, text)
				this.ruleForm.content = html
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
                        saveArticle(this.ruleForm).then(() => {
                            this.getList();
                            this.$message({
                                message: '保存成功',
                                type: 'success'
                            });
                            this.dialogFormVisible = false;
                        })
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			noticeAdd() {
                this.ruleForm.id = '';
                this.ruleForm.title = '';
                this.ruleForm.content = '';
				this.dialogFormVisible = true
			},
			indexMethod(e) {
				console.log(e)
				return (this.query.page - 1) * this.query.pagesize + e + 1
			},
			tableRowClassName({
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
			getList() {
                fetchList(this.query).then(res => {
                    this.tableData = res.data.list
                    this.total = res.data.total
                })
			},
			editClick(row) {
                this.ruleForm = Object.assign({}, row);
				this.dialogFormVisible = true
			},
			deleteClick(row) {
				this.$confirm("确定要永久删除该行公告吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
                        delArticle({ id: row.id }).then(() => {
                            this.getList();
                            this.$message({
                                message: '删除成功',
                                type: 'success'
                            });
                        })
					})
					.catch(() => {
					});
			},
			handleSort(e) {
				let field = e.prop
				if (field == 'modifyTime') {
					field = 'modify_time'
				}
				console.log(e);
				if (e.order == "ascending") {
					this.query.sort = "+" + field
				} else if (e.order == "descending") {
					this.query.sort = "-" + field
				} else {
					this.query.sort = ""
				}
				// this.query.page = 1
				this.getList()
			},
		},
	};
</script>

<style lang="scss">
</style>
